<template>
    <section class="overflow-auto" style="height: calc(100vh - 230px);">
        <div v-if="storeTipoView=='folders'" class="flex flex-wrap p-3 grid grid-cols-2 md:grid-cols-3">
            <div v-for="(data, key) in folders" :key="key" class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative m-1">
                <div class="w-20 file__icon file__icon--directory mx-auto cursor-pointer" @click="verCarpeta(data)"></div>
                <div class="block font-medium mt-4 text-center truncate">{{ data.nombre }}</div>
                <div class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                </div>
            </div>
            <p v-if="!folders.length">Sin datos</p>
        </div>
        <div v-if="storeTipoView=='files'" class="flex flex-wrap p-3 grid grid grid-cols-2 md:grid-cols-3 rounded-lg">
            <div v-for="(data, key) in files" :key="key" class="file box mb-auto rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative m-1">
                <div v-if="data.tipo=='dir'" class="w-20 file__icon file__icon--directory mx-auto cursor-pointer" @click="verCarpeta(data)"></div>
                <a v-else class="w-3/5 file__icon file__icon--file mx-auto cursor-pointer">
                    <div class="file__icon__file-name uppercase" @click="getArchivo(data)">
                        <!-- {{ data.tipo === 'pdf' || data.tipo === 'txt' ? 'PDF' : 'XLSX' }} -->
                    </div>
                </a>
                <a class="block font-medium mt-4 text-center truncate">{{ data.cod_nombre ? data.cod_nombre : data.nombre }}</a>
                <div class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                </div>
            </div>
            <p v-if="!files.length">Sin datos</p>
        </div>
        <div v-if="storeTipoView=='pdf'" class="p-3 rounded-lg h-full">
            <embed v-if="tipoView === 'pdf'" alt="Nombre del archivo PDF" type="application/pdf" :src="filePath" style="width:100%;height: 100%;">
            <embed v-if="tipoView === 'txt'" alt="Nombre del archivo PDF" type="application/pdf" :src="`data:application/pdf;base64,${filePath}#zoom=80&bookmarks=1`" style="width:100%;height: 100%;">
                <!-- <iframe width="402" height="346" frameborder="0" scrolling="no" src="https://pharmasan-my.sharepoint.com/personal/profesionalsistemas1_daily-tech_co1/_layouts/15/Doc.aspx?sourcedoc={61ff4928-e697-484d-9e84-1242efa04878}&action=embedview&AllowTyping=True&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True&edesNext=false&resen=true&ed1JS=false" style="width:100%;height: 100%;"></iframe> -->
        </div>
    </section>
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import GetServices from '../services/get.service'
import store from '../store'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
export default {
    props: {
        folders: {
            type: Array,
            default: () => ([])
        },
        files: {
            type: Array,
            default: () => ([])
        }
    },
setup (props, { emit }) {
    const _Get = ref(new GetServices())
    const _ModalSolictudesService = new ModalSolicitudesService()
    const filePath = ref('')
    const tipoView = ref('pdf')
    const storeTipoView = computed(() => store.getters.getTipoView)
    const verCarpeta = (folder) => {
        emit('verCarpeta', folder)
    }
    const getArchivo = (file) => {
        var { id } = file
        _Get.value.getArchivoPath(id).then(({ data }) => {
            file.path = data
            viewPdf(file)
        }).catch(err => {
          console.log(err.message)
        })
    }
    const viewPdf = async (file) => {
        // console.log('viewPdf--file----', file)
        // _Get.value.viewPdf({ path, nombre, tipo }).then((data) => {
        var archivo = await _Get.value.getArchivo(file.id, true).then(({ data }) => {
            return data
        }).catch(err => {
            console.log(err.message)
        })
        // console.log('archivo------', archivo)
        tipoView.value = archivo.tipo
        var dataHtml = {
          path: file.path,
          tipo: file.tipo ? file.tipo : archivo.tipo,
          nombreArchivo: file.nombre,
          version: file.version,
          cod: file.cod,
          createdAt: file.createdAt,
          updatedAt: file.updatedAt
        }
        if (archivo.tipo === 'pdf' || archivo.tipo === 'xlsx') {
            const url = _ModalSolictudesService.getUri({ path: file.path, tipo: archivo.tipo, nombre: file.cod + ' ' + file.nombre })
            // console.log('url------', url)
            if (archivo.tipo === 'pdf') {
                filePath.value = url.base ? url.base : url
                store.dispatch('updateTipoView', 'pdf')
                } else {
                window.open(url, '_blank')
                // var link = document.createElement('a')
                // link.href = url
                // link.download = file.nombre
                // link.target = '_blank'
                // link.click()
                }
        } else {
            _ModalSolictudesService.getHtml(dataHtml).then(({ data }) => {
                filePath.value = data.base ? data.base : data
                store.dispatch('updateTipoView', 'pdf')
            }).catch(err => {
              console.log(err.message)
            })
        }
    }
    onMounted(() => {
    })
    return {
        filePath,
        tipoView,
        storeTipoView,
        verCarpeta,
        getArchivo
    }
}
}
</script>
<style lang="scss">

</style>
