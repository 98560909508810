<template>
  <div class="gap-4 bg-white p-3" style="height: calc(100% - 110px);">
    <div class="flex my-2 text-2xl font-bold">
      <i class="pi pi-align-justify mx-2 my-auto"></i>
      Administrar
      <div class="ml-auto flex gap-4 mr-3">
        <i class="pi pi-list cursor-pointer" v-tooltip.top="'Descargar listado maestro'" @click="descargarListado"></i>
        <i class="pi pi-plus cursor-pointer" v-tooltip.top="'Agregar Tipo Proceso'" @click="addTipo = true"></i>
        <i class="pi pi-trash cursor-pointer text-red-300 mr-3" v-tooltip.top="'Documentos Inactivos'" @click="ListarInactivos()"></i>
      </div>
    </div>
    <div class="">
      <Collapse/>
      <div v-if="addTipo" class="border-b">
        <div class="flex p-2 bg-item">
          <InputText v-model="newTipoProceso" type="text" class="rounded-md w-6/12" @click.stop=""/>
          <!-- <Checkbox name="externo" value="Documentos externos" v-model="externo" class="my-auto mx-4" /> -->
          <InputSwitch v-if="!validarExterno(storeTiposProcesoList)" v-model="externo" class="my-auto ml-4 mr-2" />
          <label v-if="!validarExterno(storeTiposProcesoList)" for="externo" class="my-auto mr-3">Docs Externos</label>
          <i :class="`pi pi-save my-auto ml-3 mx-2 cursor-pointer`" style="font-size: 1rem"
             @click.stop="addTipoProceso(newTipoProceso)"></i>
          <i :class="`pi pi-times my-auto mx-2 cursor-pointer`" style="font-size: 1rem"
             @click.stop="addTipo = false, newTipoProceso = ''"></i>
        </div>
        <span v-if="msmtipoProceso && !newTipoProceso"
              class="text-xs text-red-600 ">El tipo de proceso es requerido</span>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, computed } from 'vue'
import Collapse from './components/collapse'
import PostServices from './services/post.service'
import GetServices from './services/get.service'
// import { v4 as uuid } from 'uuid'
import store from './store'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'

export default {
  name: 'CalidadAdministrar',
  components: {
    Collapse
  },
  setup () {
    const _ModalSolictudesService = new ModalSolicitudesService()
    const storeTiposProcesoList = computed(() => store.getters.getTipoProcesos)
    const _Post = ref(new PostServices())
    const _Get = ref(new GetServices())
    const router = useRouter()
    const items = ref([])
    const addTipo = ref(false)
    const newTipoProceso = ref()
    const msmtipoProceso = ref(false)
    const externo = ref(false)

    const addTipoProceso = (nombre) => {
      if (!newTipoProceso.value) {
        msmtipoProceso.value = true
      } else {
        _Post.value.crearTipoProceso({
          posicion: storeTiposProcesoList.value.length ? (Math.max(...storeTiposProcesoList.value.map(o => o.posicion)) + 1) : 1,
          nombre,
          externo: externo.value
        }).then(({ data }) => {
          // console.log('retornando el crear tipo proceso', data)
          Swal.fire({
            title: 'Exito',
            text: 'Tipo Proceso registrado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              addTipo.value = false
              newTipoProceso.value = ''
              getMapaProcesos()
            }
          })
        }).catch(err => {
          console.log(err.message)
        })
      }
    }

    const getMapaProcesos = () => {
      items.value = []
      _Get.value.getMapaProcesos().then(({ data }) => {
        store.dispatch('updateTiposProcesos', data)
        // console.log('getMapaProcesos-------', data)
      }).catch(err => {
        console.error(err.message)
      })
    }

    // const carpetaLeer = (targetPath) => {
    //   items.value = []
    //   _Get.value.getMapa(targetPath).then(({ data }) => {
    //     store.dispatch('updateTiposProcesos', data)
    //     // console.log('carpetaLeer-------', data)
    //   }).catch(err => {
    //     console.error(err.message)
    //   })
    // }

    const descargarListado = () => {
      const url = _ModalSolictudesService.generarListado()
      window.open(url, '_blank')
    }

    const validarExterno = () => {
          var ext = false
          storeTiposProcesoList.value.map(m => {
              if (m.externo) {
                  ext = true
              }
          })
          return ext
      }

    const ListarInactivos = () => {
      router.push({
        name: 'pharmasan.gestion.calidad.mapa-procesos.inactivos', params: { }
      })
    }

    onMounted(() => {
      getMapaProcesos()
    })
    return {
      items,
      addTipo,
      newTipoProceso,
      msmtipoProceso,
      externo,
      addTipoProceso,
      descargarListado,
      validarExterno,
      ListarInactivos
    }
  }
}
</script>
<style lang="scss">

</style>
