import http from '../../../../../../libs/http'
import calidad from '../../../../../../config/calidad'
// export default (param) => http.get(`${calidad.host}/carpetas`, param)
export default class CarpetasServices {
    moveProceso (param) {
      return http.put(`${calidad.host}/mapa-procesos/mover`, param, {
        // params: {
        //     ...param
        //   }
      })
    }

    moveCarpeta (param) {
      return http.put(`${calidad.host}/mapa-procesos/mover`, param, {
        // params: {
        //     ...param
        //   }
      })
    }

    moveDocumento (param) {
      return http.put(`${calidad.host}/mapa-procesos/mover`, param, {
        // params: {
        //     ...param
        //   }
      })
    }
  }
