<template>
  <section>
    <div class="grid grid-cols-1" :class="visualizar ? 'md:grid-cols-2' : ''">
      <div :class="visualizar ? 'actived' : ''" class="collapse-container p-1 bg-theme-2 rounded-lg ">
        <draggable :list="storeTiposProcesoList" group="tipos" @change="ordenarTipo(storeTiposProcesoList)"
                   handle=".handle" item-key="key">
          <template #item="{ element: item }">
            <div class="">
              <!-- listado tipo procesos -->
              <div :class="itemActive == item.id || item.actived == true ? 'item-actived' : ''"
                   class="flex p-2 bg-item bg-item-border rounded-lg cursor-pointer my-1 border-b">
                <i :class="`pi pi-ellipsis-v handle my-auto`" style="font-size: 1rem: cursor: -webkit-grabbing;"
                   @click.stop=""></i>
                <i :class="`pi pi-angle-${item.actived == true ? 'down' : 'right'} my-auto mx-2 text-blue-600`"
                   style="font-size: 1.4rem" @click.stop="desplegarProceso(item)"></i>
                <p v-if="editarTipo != item.id"
                   :class="`${item.tipo == 'dir' ? 'font-medium' : ''} my-auto text-lg uppercase text-blue-600 font-bold`"
                   @click="desplegarProceso(item), itemActive = item.id">{{ item.nombre }}</p>
                <InputText v-if="editarTipo == item.id" v-model="item.nombre" type="text" class="rounded-md w-6/12"
                           @click.stop=""/>
                <Button v-if="editarTipo != item.id" label="Primary"
                        class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde"
                        @click.stop="editarTipo = item.id">
                  <Edit3Icon class="block h-5"/>
                </Button>
                <InputSwitch v-if="editarTipo == item.id && !validarExterno()" v-model="item.externo"
                             class="my-auto ml-4 mr-2"/>
                <i v-if="editarTipo == item.id" :class="`pi pi-save my-auto mx-2`" style="font-size: 1rem"
                   @click.stop="actualizarTipoProceso(item)"></i>
                <i v-if="editarTipo == item.id" :class="`pi pi-times my-auto mx-2`" style="font-size: 1rem"
                   @click.stop="editarTipo = null"></i>
                <!-- agregar Tipo -->
                <Button v-if="!item.externo" label="Primary"
                        class="p-button-text p-button-rounded my-auto ml-auto mx-2 p-0 icon-hidde"
                        v-tooltip.top="'Agregar Proceso'"
                        @click.stop="desplegarProceso(item), addProceso = true, newProceso = '', item.actived = 0, addInput = item.id">
                  <PlusSquareIcon class="block mx-auto"/>
                </Button>
                <Button v-if="item.externo" label="Primary"
                        class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde ml-auto"
                        @click.stop="modalAddDoc(item)"
                        v-tooltip.top="'Agregar Archivo Externo'"
                >
                  <FilePlusIcon class="block mx-auto"/>
                </Button>
                <Button label="Primary" class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde"
                        v-tooltip.top="'Inactivar Tipo proceso'"
                        @click.stop="inactivarTipoProceso(item.id)">
                  <MinusSquareIcon class="block mx-auto text-red-400"/>
                </Button>
              </div>
              <div v-show="item.actived == true" class="collapse-content pl-5">
                <!-- procesos -->
                <div v-if="!item.externo">
                  <draggable :list="item.procesos" group="procesos"
                             @change="ordenarProceso(item.procesos, item.id, $event)"
                             handle=".handle" item-key="key">
                    <template #item="{ element: pro }">
                      <div class="">
                        <div class="flex p-2 bg-item cursor-pointer rounded-lg my-1">
                          <i :class="`pi pi-ellipsis-v handle my-auto`"
                             style="font-size: 1rem: cursor: -webkit-grabbing;"
                             @click.stop=""></i>
                          <i :class="`pi pi-angle-${pro.actived ? 'down' : 'right'} my-auto`" style="font-size: 1.4rem"
                             @click.stop="desplegarCarpeta(pro)"></i>
                          <i class="pi pi-share-alt my-auto font-bold mx-2"></i>
                          <p v-if="editarPro != pro.id"
                             :class="`${pro.tipo == 'dir' ? 'font-medium' : ''} my-auto font-bold`"
                             @click.stop="desplegarCarpeta(pro)">{{ pro.nombre }}</p>
                          <InputText v-if="editarPro == pro.id" v-model="pro.nombre" type="text"
                                     class="rounded-md w-6/12"
                                     @click.stop=""/>
                          <!-- <Dropdown v-if="editarPro == pro.id" v-model="ProcesoArea" :options="ListAreas" optionLabel="name" optionValue="id" placeholder="Seleccione un Area" class="mx-3"/> -->
                          <Button v-if="editarPro != pro.id" label="Primary"
                                  class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde"
                                  @click.stop="editarPro = pro.id">
                            <Edit3Icon class="block h-5"/>
                          </Button>
                          <!-- <i v-if="editarPro != pro.id" :class="`pi pi-pencil my-auto mx-2`" style="font-size: 1rem" ></i> -->
                          <i v-if="editarPro == pro.id" :class="`pi pi-save my-auto mx-2`" style="font-size: 1rem"
                             @click.stop="actualizarProceso(item.id, pro)"></i>
                          <i v-if="editarPro == pro.id" :class="`pi pi-times my-auto mx-2`" style="font-size: 1rem"
                             @click.stop="editarPro = null"></i>
                          <Button label="Primary"
                                  class="p-button-text p-button-rounded my-auto ml-auto mx-2 p-0 icon-hidde"
                                  @click.stop="desplegarCarpeta(pro), addCarpeta = true, newCarpeta = '', addInput = pro.id, pro.actived = 0">
                            <FolderPlusIcon class="block mx-auto"/>
                          </Button>
                          <Button label="Primary" class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde"
                                  @click.stop="inactivarProceso(pro.id, item)">
                            <MinusSquareIcon class="block mx-auto text-red-400"/>
                          </Button>
                        </div>
                        <div v-show="pro.actived" class="collapse-content pl-5 rounded-lg">
                          <!-- carpetas -->
                          <draggable v-model="pro.carpetas" group="carpetas" @start="dragging = true"
                                     @end="dragging = false"
                                     @change="ordenarCarpeta(pro.carpetas, pro.id, $event)" handle=".handle"
                                     item-key="key">
                            <template #item="{ element: folder }">
                              <div class="">
                                <div class="flex p-2 bg-item cursor-pointer rounded-lg my-1">
                                  <i :class="`pi pi-ellipsis-v handle my-auto`"
                                     style="font-size: 1rem: cursor: -webkit-grabbing;" @click.stop=""></i>
                                  <i :class="`pi pi-angle-${folder.actived ? 'down' : 'right'} my-auto`"
                                     style="font-size: 1.4rem" @click.stop="desplegarDocumentos(folder)"></i>
                                  <i :class="`pi pi-folder my-auto mx-2 font-bold`" style="font-size: 1rem"></i>
                                  <p v-if="editarFolder != folder.id"
                                     :class="`${folder.tipo == 'dir' ? 'font-medium' : ''} my-auto font-bold`"
                                     @click.stop="desplegarDocumentos(folder)">{{ folder.nombre }}</p>
                                  <InputText v-if="editarFolder == folder.id" v-model="folder.nombre" type="text"
                                             class="rounded-md w-full"/>
                                  <Button v-if="editarFolder != folder.id" label="Primary"
                                          class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde"
                                          @click.stop="editarFolder = folder.id">
                                    <Edit3Icon class="block h-5"/>
                                  </Button>
                                  <!-- <i v-if="editarFolder != folder.id" :class="`pi pi-pencil my-auto mx-3`" style="font-size: 1rem" @click.stop="editarFolder = folder.id"></i> -->
                                  <i v-if="editarFolder == folder.id" :class="`pi pi-save my-auto mx-3`"
                                     style="font-size: 1rem" @click.stop="actualizarCarpeta(pro.id, folder)"></i>
                                  <i v-if="editarFolder == folder.id" :class="`pi pi-times my-auto ml-auto mx-3`"
                                     style="font-size: 1rem" @click.stop="editarFolder = null"></i>
                                  <!-- <Button v-if="editarFolder != folder.id" label="Primary"
                                    class="p-button-text p-button-rounded my-auto ml-auto mx-2 p-0 icon-hidde"
                                    @click.stop="modalAddDoc(folder)">
                                    <FilePlusIcon class="block mx-auto" />
                                  </Button> -->
                                  <Button label="Primary"
                                    class="p-button-text p-button-rounded my-auto ml-auto mx-2 p-0 icon-hidde"
                                    @click.stop="desplegarCarpeta(folder), addCarpeta2 = true, newCarpeta2 = '', addInput = folder.id, folder.actived = 0">
                                    <FolderPlusIcon class="block mx-auto"/>
                                  </Button>
                                  <Button label="Primary"
                                          class="p-button-text p-button-rounded mx-2 p-1 icon-hidde"
                                          @click.stop="inactivarFolder(folder.id, pro)">
                                    <FolderMinusIcon class="block mx-auto text-red-400"/>
                                  </Button>
                                </div>
                                <div v-show="folder.actived" class="collapse-content pl-5">
                                  <!-- documentos -->
                                  <draggable v-model="folder.documentos" group="documentos" @start="dragging = true"
                                             @end="dragging = false"
                                             @change="ordenarDocumento(folder.documentos, folder.id, $event)"
                                             handle=".handle" item-key="key">
                                    <template #item="{ element: doc }">
                                      <div class="">
                                        <div class="flex p-2 bg-item cursor-pointer rounded-lg my-1">
                                          <i :class="`pi pi-ellipsis-v handle my-auto`"
                                             style="font-size: 1rem: cursor: -webkit-grabbing;" @click.stop=""></i>
                                          <i v-show="doc.tipo == 'dir'" :class="`pi pi-angle-${doc.actived ? 'down' : 'right'} my-auto`"
                                            style="font-size: 1.4rem" @click.stop="desplegarDocumentos(doc)"></i>
                                          <i v-show="doc.tipo != 'dir'"
                                            :class="`pi pi-file${doc.tipo == 'xlsx' ? '-excel text-green-600' : doc.tipo == 'txt' ? '-pdf text-red-600' : ''} my-auto mx-2`"
                                            style="font-size: 1rem"></i>
                                          <i v-show="doc.tipo == 'dir'"
                                            :class="`pi pi-folder my-auto mx-2`"
                                            style="font-size: 1rem"></i>
                                          <p v-if="editarDoc != doc.id" @click.stop="doc.tipo == 'dir' ? desplegarDocumentos(doc) : ''"
                                             :class="`${doc.tipo == 'dir' ? 'font-medium' : ''} my-auto `">{{ doc.tipo == 'dir' ? '' : doc.cod }}
                                            {{ doc.nombre }}</p>
                                          <InputText v-if="editarDoc == doc.id" v-model="doc.nombre" type="text"
                                                     class="rounded-md w-full" @click.stop=""/>
                                          <Button v-if="editarDoc != doc.id" label="Primary"
                                                  class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde"
                                                  @click.stop="editarDoc = doc.id">
                                            <Edit3Icon class="block h-5"/>
                                          </Button>
                                          <i v-if="editarDoc == doc.id" :class="`pi pi-save my-auto mx-3`"
                                             style="font-size: 1rem" @click.stop="actualizarDocumento(doc)"></i>
                                          <i v-if="editarDoc == doc.id" :class="`pi pi-times my-auto ml-auto mx-3`"
                                             style="font-size: 1rem" @click.stop="editarDoc = null"></i>
                                          <!-- <i :class="`pi pi-pencil mx-2 my-auto`" style="font-size: 1rem" @click.stop="modalAddDoc(folder, doc), folder.actived = 0"></i> -->
                                          <!-- ---------acciones-------------- -->
                                          <div class="dropdown ml-auto">
                                            <button
                                              class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300 bg-item icon-hidde"
                                              aria-expanded="false">
                                              <span class="w-5 h-5 flex items-center justify-center">
                                                <i class="pi pi-ellipsis-h"></i>
                                              </span>
                                            </button>
                                            <div class="dropdown-menu w-40 ">
                                              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                <!-- <button
                                                    @click="nuevaVersion(folder, doc)"
                                                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full"
                                                >
                                                    <FilePlusIcon class="block" />
                                                    <span class="truncate ml-2">Nueva Versión</span>
                                                </button> -->
                                                <button @click="verHistorial(doc.id, pro.nombre)"
                                                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full">
                                                  <i class="pi pi-align-right mx-1"></i>
                                                  <span class="truncate ml-2">Historial</span>
                                                </button>
                                                <button
                                                  @click="displayDelFile = true, newArchivo.nombre = doc.cod + doc.nombre, newArchivo.id = doc.id, newArchivo.folderId = folder.id, folder.actived = false"
                                                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full">
                                                  <FileMinusIcon class="block text-red-400"/>
                                                  <span class="truncate ml-2">Inactivar Doc</span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="doc.actived" class="collapse-content pl-5">
                                          <!-- listado de documentos carpeta interna 2 -->
                                          <draggable v-model="doc.documentos" group="documentos" @start="dragging = true"
                                                    @end="dragging = false"
                                                    @change="ordenarDocumento(doc.documentos, doc.id, $event)"
                                                    handle=".handle" item-key="key">
                                            <template #item="{ element: doc2 }">
                                              <div class="">
                                                <div class="flex p-2 bg-item cursor-pointer rounded-lg my-1">
                                                  <i :class="`pi pi-ellipsis-v handle my-auto`"
                                                    style="font-size: 1rem: cursor: -webkit-grabbing;" @click.stop=""></i>
                                                  <i v-show="doc2.tipo"
                                                    :class="`pi pi-file${doc2.tipo == 'xlsx' ? '-excel text-green-600' : doc2.tipo == 'txt' ? '-pdf text-red-600' : ''} my-auto mx-2`"
                                                    style="font-size: 1rem"></i>
                                                  <p v-if="editarDoc != doc2.id"
                                                    :class="`${doc2.tipo == 'dir' ? 'font-medium' : ''} my-auto `">{{ doc2.cod }}
                                                    {{
                                                      doc2.nombre
                                                    }}</p>
                                                  <InputText v-if="editarDoc == doc2.id" v-model="doc2.nombre" type="text"
                                                            class="rounded-md w-full" @click.stop=""/>
                                                  <Button v-if="editarDoc != doc2.id" label="Primary"
                                                          class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde"
                                                          @click.stop="editarDoc = doc2.id">
                                                    <Edit3Icon class="block h-5"/>
                                                  </Button>
                                                  <i v-if="editarDoc == doc2.id" :class="`pi pi-save my-auto mx-3`"
                                                    style="font-size: 1rem" @click.stop="actualizarDocumento(doc2)"></i>
                                                  <i v-if="editarDoc == doc2.id" :class="`pi pi-times my-auto ml-auto mx-3`"
                                                    style="font-size: 1rem" @click.stop="editarFolder = null"></i>
                                                  <!-- <i :class="`pi pi-pencil mx-2 my-auto`" style="font-size: 1rem" @click.stop="modalAddDoc(folder, doc), folder.actived = 0"></i> -->
                                                  <!-- ---------acciones-------------- -->
                                                  <div class="dropdown ml-auto">
                                                    <button
                                                      class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300 bg-item icon-hidde"
                                                      aria-expanded="false">
                                                      <span class="w-5 h-5 flex items-center justify-center">
                                                        <i class="pi pi-ellipsis-h"></i>
                                                      </span>
                                                    </button>
                                                    <div class="dropdown-menu w-40 ">
                                                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                        <!-- <button
                                                            @click="nuevaVersion(folder, doc)"
                                                            class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full"
                                                        >
                                                            <FilePlusIcon class="block" />
                                                            <span class="truncate ml-2">Nueva Versión</span>
                                                        </button> -->
                                                        <button @click="verHistorial(doc2.id, pro.nombre)"
                                                                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full">
                                                          <i class="pi pi-align-right mx-1"></i>
                                                          <span class="truncate ml-2">Historial</span>
                                                        </button>
                                                        <button
                                                          @click="displayDelFile = true, newArchivo.nombre = doc2.cod + doc2.nombre, newArchivo.id = doc2.id, newArchivo.folderId = folder.id, folder.actived = false"
                                                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full">
                                                          <FileMinusIcon class="block text-red-400"/>
                                                          <span class="truncate ml-2">Inactivar Doc</span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </template>
                                          </draggable>
                                          <p v-if="!folder.documentos.length">Sin Datos</p>
                                          <!-- agregar carpeta -->
                                          <div v-if="addCarpeta2 && addInput == folder.id" class="border-b">
                                            <div class="flex p-2 bg-item">
                                              el input de crear carpeta interna
                                              <InputText v-model="newCarpeta" type="text" class="rounded-md w-full" @click.stop=""/>
                                              <i :class="`pi pi-save my-auto ml-3 mx-2 cursor-pointer`" style="font-size: 1rem"
                                                @click.stop="crearCarpeta(newCarpeta, doc)"></i>
                                              <i :class="`pi pi-times my-auto mx-2 cursor-pointer`" style="font-size: 1rem"
                                                @click.stop="addCarpeta2 = false, newCarpeta = ''"></i>
                                            </div>
                                            <span v-if="msmCarpeta && !newCarpeta" class="text-xs text-red-600 ">El nombre de la carpeta es
                                              requerido</span>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </draggable>
                                  <p v-if="!folder.documentos.length">Sin Datos</p>
                                  <!-- agregar carpeta -->
                                  <div v-if="addCarpeta2 && addInput == folder.id" class="border-b">
                                    <div class="flex p-2 bg-item">
                                      <InputText v-model="newCarpeta" type="text" class="rounded-md w-full" @click.stop=""/>
                                      <i :class="`pi pi-save my-auto ml-3 mx-2 cursor-pointer`" style="font-size: 1rem"
                                        @click.stop="crearCarpeta(newCarpeta, folder)"></i>
                                      <i :class="`pi pi-times my-auto mx-2 cursor-pointer`" style="font-size: 1rem"
                                        @click.stop="addCarpeta2 = false, newCarpeta = ''"></i>
                                    </div>
                                    <span v-if="msmCarpeta && !newCarpeta" class="text-xs text-red-600 ">El nombre de la carpeta es
                                      requerido</span>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </draggable>
                          <p v-if="!pro.carpetas.length">Sin Datos</p>
                          <!-- agregar carpeta -->
                          <div v-if="addCarpeta && addInput == pro.id" class="border-b">
                            <div class="flex p-2 bg-item">
                              <InputText v-model="newCarpeta" type="text" class="rounded-md w-full" @click.stop=""/>
                              <i :class="`pi pi-save my-auto ml-3 mx-2 cursor-pointer`" style="font-size: 1rem"
                                 @click.stop="crearCarpeta(newCarpeta, pro)"></i>
                              <i :class="`pi pi-times my-auto mx-2 cursor-pointer`" style="font-size: 1rem"
                                 @click.stop="addCarpeta = false, newCarpeta = ''"></i>
                            </div>
                            <span v-if="msmCarpeta && !newCarpeta" class="text-xs text-red-600 ">El nombre de la carpeta es
                              requerido</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </draggable>
                </div>
                <!-- <p v-if="!item.procesos.length">Sin Datos</p> -->
                <div v-if="item.externo">
                  <div v-for="(doc, key) in item.documentos" :key="key"
                       class="flex p-2 bg-item cursor-pointer rounded-lg my-1">
                    <i :class="`pi pi-ellipsis-v handle my-auto`" style="font-size: 1rem: cursor: -webkit-grabbing;"
                       @click.stop=""></i>
                    <i
                      :class="`pi pi-${ doc.tipo === 'pdf' || doc.tipo === 'txt' ? 'file-pdf text-red-400': doc.tipo === 'xlsx' ? 'file-excel text-green-400': 'file'} my-auto mx-2`"
                      style="font-size: 1rem"></i>
                    <p :class="` my-auto `">{{ doc.cod }} {{ doc.nombre }}</p>
                    <Button label="Primary" class="p-button-text p-button-rounded ml-auto my-auto mx-2 p-0 icon-hidde"
                            @click="verHistorial(doc.id)">
                      <i class="pi pi-align-right mx-1"></i>
                    </Button>
                    <Button label="Primary" class="p-button-text p-button-rounded my-auto mx-2 p-0 icon-hidde"
                            @click.stop="modalAddDoc(null, doc)">
                      <Edit3Icon class="block h-5"/>
                    </Button>
                  </div>
                </div>
                <!-- agregar proceso -->
                <div v-if="addProceso && addInput == item.id" class="border-b">
                  <div class="flex p-2 bg-item">
                    <InputText v-model="newProceso" type="text" class="rounded-md w-6/12" @click.stop=""/>
                    <!-- <Dropdown v-model="ProcesoArea" :options="ListAreas" optionLabel="name" optionValue="id" placeholder="Seleccione un Area" class="mx-3"/> -->
                    <i :class="`pi pi-save my-auto ml-3 mx-2 cursor-pointer`" style="font-size: 1rem"
                       @click.stop="crearProceso(newProceso, item)"></i>
                    <i :class="`pi pi-times my-auto mx-2 cursor-pointer`" style="font-size: 1rem"
                       @click.stop="addProceso = false, newProceso = ''"></i>
                  </div>
                  <span v-if="msmProceso && !newProceso" class="text-xs text-red-600 ">El nombre del proceso es
                    requerido</span>
                </div>
              </div>
            </div>
          </template>
        </draggable>
        <!-- <div> -->
        <!-- <div :class="itemActive==0? 'item-actived' : ''" class="flex p-2 bg-item bg-item-border rounded-lg cursor-pointer my-1 border-b">
          <i :class="`pi pi-ellipsis-v handle my-auto`" style="font-size: 1rem: cursor: -webkit-grabbing;" @click.stop=""></i>
          <i :class="`pi pi-angle-${itemActive == 0 ? 'down':'right'} my-auto mx-2 text-blue-600`" style="font-size: 1.4rem" @click.stop="getdocumentosExternos(), itemActive==0?itemActive=null:itemActive=0"></i>
          <p :class="`font-medium my-auto text-lg uppercase text-blue-600 font-bold`" @click="getdocumentosExternos(), itemActive==0?itemActive=null:itemActive=0">DOCUMENTOS EXTERNOS</p> -->
        <!-- Agregar Doc -->
        <!-- <Button label="Primary" class="p-button-text p-button-rounded my-auto ml-auto mx-2 p-0 icon-hidde" @click.stop="modalAddDoc(null)">
          <FilePlusIcon class="block mx-auto" />
        </Button>
      </div> -->
        <!-- </div> -->
      </div>
      <!-- visualizar carpetas -->
      <div v-if="visualizar" :class="visualizar ? 'actived' : ''" class="content-view box">
        <div class="flex">
          <i v-show="storeTipoView == 'files' || storeTipoView == 'pdf' && listFolders.length"
            :class="`pi pi-angle-left my-auto cursor-pointer mx-2 my-auto`" style="font-size: 1.4rem"
            @click="desplegarCarpeta()"></i>
          <Button v-if="visualizar" label="Primary"
                  class="p-button-text p-button-rounded my-auto ml-auto mx-2 p-0 icon-hidde"
                  @click.stop="visualizar = false">
            <XCircleIcon class="block mx-auto cursor-pointer"/>
          </Button>
        </div>
        <viewFolders :folders="listFolders" :files="listFiles" @verCarpeta="desplegarDocumentos"/>
      </div>
    </div>
    <!-- BEGIN: nuevo doc -->
    <Dialog v-model:visible="displayAddDoc" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '40vw' }">
      <template v-slot:header>
        <div class="w-full">
          <div class="flex justify-center">
            <FilePlusIcon class="block mr-2 h-5"/>
            <b v-if="!updatingfile">Agregar Documento</b>
            <b v-if="updatingfile">Actualizar Documento</b>
          </div>
        </div>
      </template>
      <!-- <div class="mb-5 flex flex-col">
        <label class="mb-2">Solicitud</label>
        <Dropdown v-model="newDocumento.solicitudId" :options="ListSolicitudes" optionLabel="asunto" optionValue="id"
          placeholder="Seleccione una solicitud" />
        <span v-if="msmDocumento && !newDocumento.solicitudId" class="text-xs text-red-600 ">Debe asociar una
          solicitud</span>
      </div> -->
      <div class="mb-5 flex flex-col">
        <label class="mb-2">Area</label>
        <!-- <Dropdown v-model="newDocumento.areaId" :options="ListAreas" optionLabel="name" optionValue="id" placeholder="Seleccione un Area" class=""/> -->
        <MultiSelect v-model="newDocumento.areaId" :options="ListAreas" optionLabel="name" optionValue="id"
                     placeholder="Seleccione un Area"/>
        <span v-if="msmDocumento && !newDocumento.areaId" class="text-xs text-red-600 ">Debe asociar un area</span>
      </div>
      <div class="mb-5 flex flex-col">
        <label class="mb-2">Código</label>
        <!-- <Dropdown v-model="newDocumento.cod" :options="ListCodigos" optionLabel="name" optionValue="name" :editable="true" placeholder="Seleccione un Código"/> -->
        <InputText v-model="newDocumento.cod" type="text" class="rounded-md w-full"/>
        <!-- <AutoComplete v-model="newDocumento.cod" :suggestions="ListCodigos" @complete="searchCod($event)" field="name" /> -->
        <span v-if="msmDocumento && !newDocumento.cod" class="text-xs text-red-600 ">El codigo es requerido</span>
      </div>
      <div class="mb-5">
        <label class="mb-2">Nombre del documento</label>
        <InputText v-model="newDocumento.nombre" type="text" class="rounded-md w-full"/>
        <span v-if="msmDocumento && !newDocumento.nombre" class="text-xs text-red-600 ">El nombre es requerido</span>
      </div>
      <div class="">
        <button class="w-full">
          <FilePond ref="pond"/>
        </button>
        <!-- <span v-if="!adjunto" class="text-xs text-red-600 ">El archivo es requerido</span> -->
      </div>
      <template #footer>
        <Button v-if="!updatingfile" label="Si" icon="pi pi-check" autofocus @click="openModalValidacion()"/>
        <Button v-if="updatingfile" label="Actualizar" icon="pi pi-check" autofocus
                @click="openModalValidacionUpdate()"/>
        <Button label="No" icon="pi pi-times" class="p-button-secondary p-button-outlined"
                @click="displayAddDoc = false"/>
      </template>
    </Dialog>
    <Toast/>
    <!-- END: nuevo doc -->
    <!-- BEGIN: nuevo archivo -->
    <Dialog v-model:visible="displayAddFile" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '40vw' }">
      <template v-slot:header>
        <div class="w-full">
          <div class="flex justify-center">
            <FilePlusIcon class="block mr-2 h-5"/>
            <b>Agregar Nueva versión</b>
          </div>
        </div>
      </template>
      <div class="mb-5 flex flex-col">
        <label class="mb-2">Solicitud</label>
        <Dropdown v-model="newArchivo.solicitudId" :options="ListSolicitudes" optionLabel="asunto" optionValue="id"
                  placeholder="Seleccione una solicitud"/>
        <span v-if="msmDocumento && !newArchivo.solicitudId" class="text-xs text-red-600 ">Debe asociar una
          solicitud</span>
      </div>
      <div class="mb-5">
        <label class="mb-2">Nombre del documento</label>
        <InputText disabled v-model="newArchivo.nombre" type="text" class="rounded-md w-full"/>
      </div>
      <div class="">
        <button class="w-full">
          <FilePond ref="pond"/>
        </button>
        <!-- <span v-if="!adjunto" class="text-xs text-red-600 ">El archivo es requerido</span> -->
      </div>
      <template #footer>
        <Button label="Si" icon="pi pi-check" autofocus @click="openModalValidacionArchivo()"/>
        <Button label="No" icon="pi pi-times" class="p-button-secondary p-button-outlined"
                @click="displayAddFile = false"/>
      </template>
    </Dialog>
    <Toast/>
    <!-- END: nuevo archivo -->
    <!-- BEGIN: inabilitar -->
    <Dialog v-model:visible="displayDelFile" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '40vw' }">
      <template v-slot:header>
        <div class="w-full">
          <div class="flex justify-center">
            <FileMinusIcon class="block mr-2 h-5"/>
            <b>Inactivar Documento</b>
          </div>
        </div>
      </template>
      <div class="mb-5">
        <label class="mb-2">Nombre del documento</label>
        <InputText disabled v-model="newArchivo.nombre" type="text" class="rounded-md w-full"/>
      </div>
      <div class="mb-5 flex flex-col">
        <label class="mb-2">Justificación</label>
        <Textarea v-model="newArchivo.razonInactivacion" rows="2" autoResize/>
        <span v-if="msmDocumento && !newArchivo.razonInactivacion" class="text-xs text-red-600 ">Debe ingresar una
          justificación</span>
      </div>
      <template #footer>
        <Button label="Si" icon="pi pi-check" autofocus @click="inactivarDocumento()"/>
        <Button label="No" icon="pi pi-times" class="p-button-secondary p-button-outlined"
                @click="displayDelFile = false"/>
      </template>
    </Dialog>
    <Toast/>
    <!-- END: inabilitar -->
  </section>
</template>
<script>
import { useRouter } from 'vue-router'
import { onMounted, ref, computed } from 'vue'
import GetServices from '../services/get.service'
import PostService from '../services/post.service'
import PutService from '../services/put.service'
import MoveService from '../services/move.service'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import documentoDescargar from '../services/documentoDescargar.service'
import draggable from 'vuedraggable'
import FilePondStore from '../filepond.store'
import FilePond from '../components/filePound.vue'
import { string, object } from 'yup'
import { useField, useForm } from 'vee-validate'
import store from '../store'
import viewFolders from './viewFolders'

export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    draggable,
    FilePond,
    viewFolders
  },
  setup (props, context) {
    const router = useRouter()
    const storeTiposProcesoList = computed(() => store.getters.getTipoProcesos)
    const storeTipoView = computed(() => store.getters.getTipoView)
    const enabled = true
    const dragging = false
    const checkMove = (e) => {
      console.log('Future index: ', e.draggedContext.futureIndex)
    }
    const toast = useToast()

    const visualizar = ref(false)
    const itemActive = ref(null)
    const tipoProcesos = ref([])
    const solicitudId = ref(0)
    const actived = ref(0)
    const tipoActived = ref(0)
    const procesoActived = ref(0)
    const carpetaActived = ref(null)
    const editarTipo = ref(null)
    const editarPro = ref(null)
    const editarFolder = ref(null)
    const editarDoc = ref(null)
    const addInput = ref(0)
    // const procesos = ref([])
    const carpetas = ref([])
    const documentos = ref([])
    const documentosExternos = ref([])
    const ListAreas = ref([{ name: 'area1', id: '1' }, { name: 'area2', id: '2' }])
    const ListCodigos = ref(['cod1', 'cod2'])
    const ListSolicitudes = ref([])
    const listFolders = ref([])
    const listFiles = ref([])
    // const tipoView = ref('folders')
    const ProcesoArea = ref(null)
    const CodigoDoc = ref()
    const addProceso = ref(false)
    const newProceso = ref()
    const msmProceso = ref(false)
    const msmCarpeta = ref(false)
    const msmDocumento = ref(false)
    const addCarpeta = ref(false)
    const addCarpeta2 = ref(false)
    const updatingfile = ref(false)
    const newCarpeta = ref('')
    const newCarpeta2 = ref('')
    const addDocumento = ref(false)
    const CarpetaDocumentos = ref({})
    // const newDocumento = ref({})
    const newArchivo = ref({})
    const pond = ref()
    const displayAddDoc = ref(false)
    const displayAddFile = ref(false)
    const displayDelFile = ref(false)
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    const _Get = ref(new GetServices())
    const _Post = ref(new PostService())
    const _Put = ref(new PutService())
    const _Move = ref(new MoveService())
    const extensionesPermitidas = [
      '.docx',
      '.doc',
      '.dot',
      '.pdf',
      '.xltx',
      '.xlsx',
      '.xls',
      '.jpg',
      '.txt'
    ]

    const searchCod = (event) => {
      ListCodigos.value = ListCodigos.value.search(event.query)
    }

    const alertar = (mensaje, tipo) => {
      const icon = tipo === 1 ? 'warning' : 'warning'
      Swal.fire({
        icon: icon,
        title: ` <span style="line-height: normal;">${mensaje}</span>`
      })
    }

    const validationSchema = object().shape({
      nombre: string().required().label('Este campo es requerido'),
      carpetaId: string().required().label('Motivo de autorización'),
      posicion: string().required().label('Mes capitado')
    })

    const { values: newDocumento, errors, handleSubmit } = useForm({
      validationSchema
    })
    const validarFormulario = handleSubmit((values) => {
      // console.log('validar formulario', newDocumento)
      if (Object.values(newDocumento).some(a => !a)) {
        alertar('Faltan campos por llenar')
      } else {
        crearDocumento()
      }
    })

    const getIcon = (label) => {
      const labelTmp = label.toLowerCase()
      if (['.docx', '.doc', '.dot', '.txt'].some(a => labelTmp.includes(a))) return 'icon icon-file-word color-doc-word'
      else if (['.jpg', '.png'].some(a => labelTmp.includes(a))) return 'pi pi-fw  pi-image'
      else if (['.mp4'].some(a => labelTmp.includes(a))) return 'pi pi-fw  pi-video'
      else if (['.pdf'].some(a => labelTmp.includes(a))) return 'pi pi-fw  pi-file-pdf'
      else if (['.xltx', '.xlsx', '.xls'].some(a => labelTmp.includes(a))) return 'pi pi-fw  pi-file-excel'
      return 'pi pi-fw  pi-folder'
    }

    const desplegar = () => {
      tipoProcesos.value = []
      _Get.value.getMapa().then(({ data }) => {
        for (const row of data) {
          tipoProcesos.value.push({
            id: row.id,
            // key: uuid(),
            nombre: row.nombre,
            posicion: row.posicion,
            procesos: []
          })
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    const getMapaProcesos = () => {
      _Get.value.getMapaProcesos().then(({ data }) => {
        store.dispatch('updateTiposProcesos', data)
      }).catch(err => {
        console.error(err.message)
      })
    }

    const desplegarProceso = (event) => {
      // console.log('desplegar proceso', event)
      if (event.externo) {
        desplegarDocumentos(event)
      } else {
        _Get.value.getprocesos({ padre: event.id }).then(({ data }) => {
          const itemsTmp = []
          for (const row of data) {
            itemsTmp.push({
              nombre: row.nombre,
              icon: getIcon(row.nombre),
              id: row.id,
              posicion: row.posicion,
              actived: false,
              carpetas: []
              // areaId: row.areaId
            })
          }
          event.actived = event.actived ? 0 : true
          event.procesos = itemsTmp
        }).catch(err => {
          console.log(err.message)
        })
      }
    }

    const desplegarCarpeta = (event) => {
      if (event) {
        _Get.value.getcarpetas({ padre: event.id }).then(({ data }) => {
          const itemsTmp = []
          for (const row of data) {
            itemsTmp.push({
              nombre: row.nombre,
              icon: getIcon(row.nombre),
              id: row.id,
              posicion: row.posicion,
              actived: false,
              documentos: []
            })
          }
          event.actived = event.actived ? 0 : true
          event.carpetas = itemsTmp
          listFolders.value = itemsTmp
          visualizar.value = true
          // tipoView.value = 'folders'
          store.dispatch('updateTipoView', 'folders')
        }).catch(err => {
          console.log(err.message)
        })
      } else {
        store.dispatch('updateTipoView', 'folders')
      }
    }

    const desplegarDocumentos = (event) => {
      // console.log('desplegarDocumentos--', event)
      _Get.value.getdocumentos({ padre: event.id }).then(async ({ data }) => {
        const itemsTmp = []
        // console.log('getdocumentos--', data)
        for (const row of data) {
          // var archivo = await _Get.value.getArchivo(row.id, true).then(({ data }) => {
          //   return data
          // }).catch(err => {
          //   console.log(err.message)
          // })
          var arraynombre = row.nombre.split(' ')
          var datos = {
            cod: arraynombre.shift(),
            nombre: row.archivos ? arraynombre.join(' ') : row.nombre,
            tipo: row.archivos ? row.archivos.tipo : 'dir',
            id: row.id,
            area: row.areaId,
            posicion: row.posicion
            // version: archivo.version,
            // createdAt: archivo.solicitud ? archivo.solicitud.createdAt.substring(0, 10) : archivo.createdAt.substring(0, 10),
            // updatedAt: archivo.solicitud ? archivo.solicitud.updatedAt.substring(0, 10) : archivo.updatedAt.substring(0, 10)
            // areaId: row.areaId
          }
          datos.cod_nombre = datos.cod + ' ' + datos.nombre
          itemsTmp.push(datos)
        }
        event.actived = event.actived ? 0 : true
        event.documentos = itemsTmp
        listFiles.value = itemsTmp
        visualizar.value = true
        // tipoView.value = 'files'
        store.dispatch('updateTipoView', 'files')
      }).catch(err => {
        console.log(err.message)
      })
    }

    const documentosVer = (event) => {
      const { nombre, itemPath } = event
      if (extensionesPermitidas.some(a => nombre.toLowerCase().includes(a))) {
        documentoProcesar(`${itemPath}/${nombre}`)
      } else {
        _Get.value.getdocumentos({ padre: event.id }).then(({ data }) => {
          const itemsTmp = []
          for (const row of data) {
            const datos = {
              nombre: row.nombre,
              icon: getIcon(row.nombre),
              id: row.id,
              posicion: row.posicion
            }
            itemsTmp.push(datos)
          }
          // carpetaActived.value = carpetaActived.value === event.id ? 0 : event.id
          // if (itemsTmp.length) documentos.value = itemsTmp
          event.actived = event.actived ? 0 : true
          event.documentos = itemsTmp
        }).catch(err => {
          console.log(err.message)
        })
      }
    }

    const documentoProcesar = (filePath) => {
      const url = documentoDescargar(filePath)
      console.log(url)
      // window.open(url, 'Download')
      const elLink = document.createElement('a')
      elLink.href = url
      elLink.target = '_blank'
      // elLink.download = true
      elLink.click()
    }

    const getAreas = () => {
      _Get.value.getAreas().then(({ data }) => {
        // console.log('el area-----------', data)
        ListAreas.value = data
      }).catch(err => {
        console.log(err.message)
      })
    }

    // const getSolicitudes = () => {
    //   _Get.value.getSolicitudes().then(({ data }) => {
    //     ListSolicitudes.value = data.rows.filter(m => m.estadoId === 3) // 3 = Aprobado
    //     // console.log('getSolicitudes-----------', data.rows)
    //   }).catch(err => {
    //     console.log(err.message)
    //   })
    // }

    const crearProceso = (nombre, tipProceso) => {
      // console.log('tipProceso--------', tipProceso)
      if (!newProceso.value) {
        msmProceso.value = true
      } else {
        var object = {
          nombre,
          padre: tipProceso.id,
          posicion: tipProceso.procesos.length ? (Math.max(...tipProceso.procesos.map(o => o.posicion)) + 1) : 1
          // areaId: ProcesoArea.value
        }
        _Post.value.crearProceso(object).then(({ data }) => {
          Swal.fire({
            title: 'Exito',
            text: 'Proceso registrado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              desplegarProceso(tipProceso)
              tipoActived.value = 0
              addProceso.value = false
              newProceso.value = ''
            }
          })
        }).catch(err => {
          console.log(err.message)
        })
      }
    }

    const crearCarpeta = (nombre, proceso) => {
      if (!newCarpeta.value) {
        msmCarpeta.value = true
      } else {
        // console.log('lo qu eenvia cre--', { nombre, procesoId, posicion: carpetas.value.length ? (Math.max(...carpetas.value.map(o => o.posicion)) + 1) : 1 })
        _Post.value.crearCarpeta({
          nombre,
          padre: proceso.id,
          posicion: carpetas.value.length ? (Math.max(...carpetas.value.map(o => o.posicion)) + 1) : 1
        }).then(({ data }) => {
          Swal.fire({
            title: 'Exito',
            text: 'Carpeta registrada con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              desplegarCarpeta(proceso)
              procesoActived.value = 0
              addCarpeta.value = false
              addCarpeta2.value = false
              newCarpeta.value = ''
              newCarpeta2.value = ''
            }
          })
        }).catch(err => {
          console.log(err.message)
        })
      }
    }

    const modalAddDoc = (folder = null, doc = null) => {
      if (folder) {
        CarpetaDocumentos.value = folder
        displayAddDoc.value = true
        folder.actived = true
        newDocumento.carpetaId = folder.id
        newDocumento.nombre = ''
        newDocumento.cod = null
        if (!folder.documentos) {
          folder.documentos = []
        }
        newDocumento.posicion = folder.documentos.length ? (Math.max(...folder.documentos.map(o => o.posicion)) + 1) : 1
        updatingfile.value = false
      } else {
        newDocumento.carpetaId = 0
        newDocumento.posicion = documentosExternos.value.length ? (Math.max(...documentosExternos.value.map(o => o.posicion)) + 1) : 1
      }
      if (doc) {
        displayAddDoc.value = true
        updatingfile.value = true
        newDocumento.cod = doc.cod
        newDocumento.nombre = doc.nombre
        newDocumento.tipo = doc.tipo
        newDocumento.posicion = doc.posicion
        newDocumento.documentoId = doc.id
        delete newDocumento.carpetaId
        _Get.value.getArchivo(doc.id).then(({ data }) => {
          newDocumento.archivoId = data[0].id
          newDocumento.areaId = data[0].mapaProceso.areas.map(a => a.areaId)
          // newDocumento.solicitudId = data[0].solicitudId
          // filePath.value = data
        }).catch(err => {
          console.log(err.message)
        })
      }
    }

    const nuevaVersion = (folder, doc = null) => {
      CarpetaDocumentos.value = folder
      displayAddFile.value = true
      folder.actived = true
      newArchivo.value.nombre = doc.cod + ' ' + doc.nombre
      newArchivo.value.documentoId = doc.id
      updatingfile.value = false
    }

    const openModalValidacion = () => {
      pond.value.onSubmitSupport().then(() => {
        console.log('procesando archivo...')
        if (Object.values(newDocumento).some(a => !a)) {
          alertar('Faltan campos por llenar')
          msmDocumento.value = true
        } else {
          msmDocumento.value = false
          if (adjunto.value.urlPath) {
            crearDocumento()
          } else {
            alertar('Debe seleccionar un archivo')
          }
        }
      }).catch(err => {
        console.log(err.message)
      })
    }

    const openModalValidacionArchivo = () => {
      pond.value.onSubmitSupport().then(() => {
        console.log('procesando archivo...')
        msmDocumento.value = false
        // console.log('newArchivo.value---', newArchivo.value)
        if (!newArchivo.value.solicitudId) {
          msmDocumento.value = true
        } else {
          if (adjunto.value.urlPath) {
            crearArchivo()
          } else {
            alertar('Debe seleccionar un archivo')
          }
        }
      }).catch(err => {
        console.log(err.message)
      })
    }

    const openModalValidacionUpdate = () => {
      pond.value.onSubmitSupport().then(() => {
        console.log('procesando archivo...')
        if (Object.values(newDocumento).some(a => !a)) {
          alertar('Faltan campos por llenar')
          msmDocumento.value = true
        } else {
          msmDocumento.value = false
          updateDocumento()
        }
      }).catch(err => {
        console.log(err.message)
      })
    }

    const inactivarTipoProceso = (id) => {
      Swal.fire({
        title: 'Esta seguro/a?',
        text: 'de inactivar el Tipo de proceso?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, inactivar!'
      }).then((result) => {
        if (result.isConfirmed) {
          _Post.value.remove(id).then(({ data }) => {
            // console.log('lo que retorna crear archivo----', data)
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se inactivó el tipo de proceso', life: 3000 })
            getMapaProcesos()
          }).catch(err => {
            console.log(err.message)
          })
        }
      })
    }

    const inactivarProceso = (id, item) => {
      Swal.fire({
        title: 'Esta seguro/a?',
        text: 'de inactivar el proceso?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, inactivar!'
      }).then((result) => {
        if (result.isConfirmed) {
          _Post.value.remove(id).then(({ data }) => {
            // console.log('lo que retorna crear archivo----', data)
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se inactivó el proceso', life: 3000 })
            desplegarCarpeta({ id: id })
            item.actived = 0
          }).catch(err => {
            console.log(err.message)
          })
        }
      })
    }

    const inactivarFolder = (id, pro) => {
      Swal.fire({
        title: 'Esta seguro/a?',
        text: 'de inactivar la carpeta?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, inactivar!'
      }).then((result) => {
        if (result.isConfirmed) {
          _Post.value.remove(id).then(({ data }) => {
            // console.log('lo que retorna crear archivo----', data)
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se inactivó la carpeta', life: 3000 })
            // desplegarDocumentos(CarpetaDocumentos.value)
            pro.actived = 0
          }).catch(err => {
            console.log(err.message)
          })
        }
      })
    }

    const inactivarDocumento = () => {
      Swal.fire({
        title: 'Esta seguro/a?',
        text: 'Esta accion no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, inactivar!'
      }).then((result) => {
        if (result.isConfirmed) {
          if (!newArchivo.value.razonInactivacion) {
            msmDocumento.value = true
          } else {
            // var envia = {
            //   id: newArchivo.value.id,
            //   razonInactivacion: newArchivo.value.razonInactivacion
            // }
            _Post.value.remove(newArchivo.value.id).then(({ data }) => {
              // console.log('lo que retorna crear archivo----', data)
              Swal.fire({
                title: 'Exito',
                text: 'Documento inactivo',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                  desplegarDocumentos({ id: newArchivo.value.folderId })
                  newArchivo.value = {}
                  displayDelFile.value = false
                }
              })
            }).catch(err => {
              console.log(err.message)
            })
          }
        }
      })
    }

    const crearDocumento = () => {
      var enviando = {
        nombre: newDocumento.cod + ' ' + newDocumento.nombre,
        padre: newDocumento.carpetaId,
        posicion: newDocumento.posicion,
        areaId: newDocumento.areaId,
        externo: true
      }
      // console.log('creando docuemnto---', enviando)
      _Post.value.crearDocumento(enviando).then(({ data }) => {
        var newArchivo = {
          path: adjunto.value.urlPath,
          padre: newDocumento.documentoId ? newDocumento.documentoId : data.id,
          tipo: adjunto.value.ext,
          nombre: newDocumento.cod + ' ' + newDocumento.nombre
          // solicitudId: newDocumento.solicitudId ? newDocumento.solicitudId : null
        }
        _Post.value.crearArchivo(newArchivo).then(({ data }) => {
          Swal.fire({
            title: 'Exito',
            text: 'Documento registrado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              carpetaActived.value = 0
              addDocumento.value = false
              newDocumento.value = {}
              displayAddDoc.value = false
              desplegarDocumentos({ id: newDocumento.carpetaId })
            }
          })
        }).catch(err => {
          console.log(err.message)
        })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const crearArchivo = () => {
      var archivo = {
        nombre: newArchivo.value.nombre,
        fileString: adjunto.value.urlPath,
        extensionArchivo: 'pdf',
        documentoId: newArchivo.value.documentoId,
        solicitudId: newArchivo.value.solicitudId
      }
      _Post.value.crearArchivo(archivo).then(({ data }) => {
        // console.log('lo que retorna crear archivo----', data)
        Swal.fire({
          title: 'Exito',
          text: 'Archivo registrado con exito',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            desplegarDocumentos(CarpetaDocumentos.value)
            // carpetaActived.value = 0
            // addDocumento.value = false
            newArchivo.value = {}
            displayAddFile.value = false
          }
        })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const updateDocumento = () => {
      // console.log('updating...')
      var enviando = {
        id: newDocumento.documentoId,
        nombre: newDocumento.cod + ' ' + newDocumento.nombre,
        carpetaId: newDocumento.carpetaId,
        posicion: newDocumento.posicion
      }
      _Put.value.putDocumento(enviando).then(({ data }) => {
        // newDocumento.idDoc = data ? data.id : ''
        if (adjunto.value.urlPath) {
          var newArchivo = {
            // nombre: newDocumento.nombre,
            fileString: adjunto.value.urlPath,
            padre: newDocumento.documentoId,
            tipo: newDocumento.tipo
          }
          _Put.value.reemplazarArchivo(newDocumento.documentoId, newArchivo).then(({ data }) => {
            Swal.fire({
              title: 'Exito',
              text: 'Documento actualizado con exito',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                desplegarDocumentos(CarpetaDocumentos.value)
                carpetaActived.value = 0
                addDocumento.value = false
                newDocumento.value = {}
                displayAddDoc.value = false
                newDocumento.nombre = ''
              }
            })
          }).catch(err => {
            console.log(err.message)
          })
        } else {
          _Put.value.putArchivo({ id: newDocumento.archivoId, nombre: newDocumento.nombre }).then(({ data }) => {
            Swal.fire({
              title: 'Exito',
              text: 'El nombre fue actualizado con exito',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                desplegarDocumentos(CarpetaDocumentos.value)
                carpetaActived.value = 0
                addDocumento.value = false
                newDocumento.value = {}
                displayAddDoc.value = false
                newDocumento.nombre = ''
              }
            })
          }).catch(err => {
            console.log(err.message)
          })
        }
      }).catch(err => {
        console.log(err.message)
      })
    }

    const actualizarTipoProceso = (tipo) => {
      var { id, nombre, externo } = tipo
      _Put.value.putTipoProceso({ id, nombre, externo }).then(({ data }) => {
        // context.emit('myArrayar', { id: 0 })
        editarTipo.value = 0
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizo correctamente', life: 3000 })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const editarProceso = (pro) => {
      // ProcesoArea.value = pro.areaId ? pro.areaId : 1
      editarPro.value = pro.id
    }

    const actualizarProceso = (tipoId, proceso) => {
      var { id, nombre, posicion } = proceso
      var object = { id, nombre, posicion, tipProcesoId: tipoId }
      _Put.value.putProceso(object).then(({ data }) => {
        desplegarProceso({ id: tipoId })
        editarPro.value = 0
        tipoActived.value = 0
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizo correctamente', life: 3000 })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const actualizarCarpeta = (proId, folder) => {
      var { id, nombre } = folder
      _Put.value.putCarpeta({ id, nombre, procesoId: proId }).then(({ data }) => {
        desplegarCarpeta({ id: proId })
        editarFolder.value = 0
        procesoActived.value = 0
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizo correctamente', life: 3000 })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const actualizarDocumento = (doc) => {
      var { id, nombre } = doc
      _Put.value.putDocumento({ id, nombre }).then(({ data }) => {
        editarDoc.value = 0
        carpetaActived.value = 0
      }).catch(err => {
        console.log(err.message)
      })
    }

    const ordenarTipo = (data, $event) => {
      data.map((m, key) => {
        m.posicion = key + 1
      })
      _Put.value.ordenar(data).then(({ data }) => {
        // context.emit('myArrayar', { id: 0 })
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizo correctamente', life: 3000 })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const ordenarProceso = (data, tipProcesoId, $event) => {
      data.map((m, key) => {
        m.posicion = key + 1
      })
      if ($event.added) {
        var idmove = $event.added.element.id
        _Move.value.moveProceso({ id: idmove, padre: tipProcesoId }).then(({ data }) => {
          // toast.add({ severity: 'success', summary: 'Exito', detail: 'Se cambio de posición con exito', life: 3000 })
          // Swal.fire({
          //     title: 'Exito',
          //     text: 'Se cambio de posición con exito',
          //     icon: 'success',
          //     showCancelButton: false,
          //     confirmButtonText: 'Ok'
          // })
        }).catch(err => {
          console.log(err.message)
        })
      }
      _Put.value.ordenar(data).then(({ data }) => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se cambio de posición con exito', life: 3000 })
        desplegarProceso({ id: tipProcesoId })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const ordenarCarpeta = (data, procesoId, $event) => {
      data.map((m, key) => {
        m.posicion = key + 1
      })
      if ($event.added) {
        var idmove = $event.added.element.id
        _Move.value.moveCarpeta({ padre: idmove, procesoId }).then(({ data }) => {
          _Put.value.ordenar(data).then(({ data }) => {
          }).catch(err => {
            console.log(err.message)
          })
        }).catch(err => {
          console.log(err.message)
        })
      }
      _Put.value.ordenar(data).then(({ data }) => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se cambio de posición con exito', life: 3000 })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const ordenarDocumento = (data, carpetaId, $event) => {
      data.map((m, key) => {
        m.posicion = key + 1
      })
      if ($event.added) {
        var idmove = $event.added.element.id
        _Move.value.moveDocumento({ padre: carpetaId, id: idmove }).then(({ data }) => {
        }).catch(err => {
          console.log(err.message)
        })
      }
      _Put.value.ordenar(data).then(({ data }) => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se cambio de posición con exito', life: 3000 })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const verHistorial = (id, proceso = '') => {
      router.push({
        name: 'pharmasan.gestion.calidad.mapa-procesos.historial', params: { id, proceso }
      })
    }

    const validarExterno = () => {
      var ext = false
      storeTiposProcesoList.value.map(m => {
        if (m.externo) {
          ext = true
        }
      })
      return ext
    }

    useField('nombre', null, {
      initialValue: ''
    })
    useField('carpetaId', null, {
      initialValue: ''
    })

    onMounted(() => {
      // desplegar()
      getAreas()
      // getSolicitudes()
    })
    // watch(FilePondStore, () => {
    //     console.log('adjunto---------', adjunto)
    // })
    return {
      errors,
      itemActive,
      visualizar,
      adjunto,
      solicitudId,
      pond,
      enabled,
      dragging,
      checkMove,
      tipoProcesos,
      storeTiposProcesoList,
      actived,
      editarTipo,
      editarPro,
      editarFolder,
      editarDoc,
      addInput,
      // procesos,
      updatingfile,
      carpetas,
      documentos,
      documentosExternos,
      ListAreas,
      ListCodigos,
      ListSolicitudes,
      listFolders,
      listFiles,
      // tipoView,
      addProceso,
      newProceso,
      ProcesoArea,
      CodigoDoc,
      addCarpeta,
      addCarpeta2,
      newCarpeta,
      newCarpeta2,
      addDocumento,
      newDocumento,
      newArchivo,
      tipoActived,
      procesoActived,
      carpetaActived,
      displayAddDoc,
      displayAddFile,
      displayDelFile,
      msmProceso,
      msmCarpeta,
      msmDocumento,
      storeTipoView,
      searchCod,
      modalAddDoc,
      nuevaVersion,
      ordenarTipo,
      ordenarProceso,
      ordenarCarpeta,
      ordenarDocumento,
      getMapaProcesos,
      desplegarProceso,
      desplegarCarpeta,
      desplegarDocumentos,
      getIcon,
      desplegar,
      getAreas,
      // getSolicitudes,
      inactivarDocumento,
      inactivarTipoProceso,
      inactivarProceso,
      inactivarFolder,
      crearProceso,
      crearCarpeta,
      crearDocumento,
      crearArchivo,
      updateDocumento,
      actualizarTipoProceso,
      editarProceso,
      actualizarProceso,
      actualizarCarpeta,
      actualizarDocumento,
      documentosVer,
      openModalValidacion,
      openModalValidacionUpdate,
      openModalValidacionArchivo,
      validarFormulario,
      verHistorial,
      validarExterno
    }
  }
}
</script>
<style lang="scss">
.bg-item {
  background: rgba(250, 251, 252, 1);

  .icon-hidde {
    // visibility: hidden;
    display: none;
    color: black;
  }

  &:hover {
    background: rgba(237, 242, 247, 1);

    .icon-hidde {
      // visibility: visible;
      display: block;

      &:hover {
        color: blue;
      }
    }
  }

  &.item-actived {
    background: rgba(237, 242, 247, 1);

    .icon-hidde {
      // visibility: visible;
      display: block;

      &:hover {
        color: blue;
      }
    }
  }
}

.bg-item-border {
  border-bottom: solid 4px transparent;

  &:hover {
    border-color: rgba(37, 99, 235, 1);
  }

  &.item-actived {
    border-color: rgba(37, 99, 235, 1);
  }
}

.collapse-container {
  width: 100%;
  transition-duration: 1s;

  // &.actived {
  //   width: 55%;
  // }
}

.content-view {
  width: 0%;
  transition-duration: 1s;
  box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.2);

  &.actived {
    width: 100%;
  }
}

:global(.swal2-container) {
  z-index: 1000000 !important;
}</style>
